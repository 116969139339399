const constants = {
  appConfig: {
    waitTimeInMiliSeconds: 100,
    appName: "AI Command",
    urls: {
      chrome: "https://chrome.google.com/webstore/detail/iinfmfmneepgdhlfeibcdmmdaflfjaic",
    },
    // put extension key here if required which would only be used in development mode
    key:
      "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhnjCHD79hDbWfqjnP9SW\nN0AXmXHh5eVk2D0oMILadmj/2uRQm/jNpnlvjVHY+899gdYRH/k7v4f+qF6gV3Yh\nwb/2PnW9PEo2VksgiixSesccvcwRXEJSngtxNWOARYSOTqTCCum9rjFVPZL+voh7\ndUsTN45D+RBLQORYbu5NtSM6RcGhUbYvhAosKwo+lsOAjM5niQDLJi//dZ9R32tc\n6tht4XrP59CTWBo8JCpIcHT6edN2HQ2vRiO5CHVb2jfT1hrO82WZ9LWsErzo/gCr\nXyIvnnXjg1wif7++WTi5mQl05Ohv3vKazNLeFpl/2XxvJ1xw4Pfh7m2qMaBJd7ll\n3QIDAQAB\n-----END PUBLIC KEY-----",
    endpoint: "https://api.aicommand.xyz",
  },
  browser: {
    firefox: {
      manifest: {
        browser_specific_settings: {
          gecko: {
            id: "fxnoob71@gmail.com",
            strict_min_version: "42.0",
          },
        },
      },
    },
  },
  support: {
    premium: "https://app.aicommand.xyz/login",
    terms: "https://aicommand.xyz/terms",
    privacyPolicy: "https://aicommand.xyz/privacy",
    donate: "https://www.patreon.com/fxnoob",
    howToVideoLink: "https://www.youtube.com/watch?v=Irpdl9Rfxhc",
    uninstallFeedbackForm: "https://forms.gle/UjxUpig1hMK4mPXU8",
  },
  promotion: {
    website: "https://aicommand.xyz/",
    voiceTypingExtension:
      "https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf",
  },
};

module.exports = constants;
