import React from 'react';

export default function Terms() {
    return (
        <div id="__next">
            <div className="docs">
                <div>
                    <h1>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <strong>aicommand.xyz's TERMS AND SERVICES</strong>
                    </h1>
                    <ul>
                        <li>Effective: FEBRUARY 1, 2020.</li>
                    </ul>
                    <h1>
                        <strong>WELCOME TO aicommand.xyz!</strong>
                    </h1>
                    <p>
                        Respect for your privacy is aicommand.xyz’s reason for existing. Use Image to text(OCR) to scan Images and Pdfs
                        using out best accuracy OCR technology.
                    </p>
                    <p>
                        aicommand.xyz provides a OCR Technology for images and pdfs. (the &quot;Site&quot;), the Image to Text (OCR) browser
                        extension and website. (the “Services”)and related Internet services (collectively, the “Service(s)”). The Service
                        is operated by aicommand.xyz inc. (the “Company”, “we” or “us”) for users of the Service (“you”). This Privacy
                        Policy sets forth our policy with respect to information that is collected from visitors to the Site and users of
                        the App and/or the Services. Under applicable law, aicommand.xyz inc. is the “data controller” of personal data
                        collected through the Services.
                    </p>
                    <h1>
                        <strong>INFORMATION WE COLLECT</strong>
                    </h1>
                    <p>
                        When you interact with us through the Services, we may collect information from you, as further described
                        below:Information You Provide: Wecollect information from you when you voluntarily provide such information, such as
                        when you register for access to the Services or use certain Services. Information we collect may include but not be
                        limited to username, email address, and anymessages or other content you send via the chat feature.
                    </p>
                    <h2>OTHER INFORMATION:</h2>
                    <ul>
                        <li>
                            <p>
                                Data We Collect Automatically: When you interact with us through the Services, we receive and store certain
                                information such as an IP address, device ID, and your activities within the Services. We may store such
                                information or such information may be included in databases owned and maintained by affiliates, agents or
                                service providers. The Services may use such information and pool it with other information to track, for
                                example, the total number of visitors to our Site, the number of images and pdfs users have uploaded to do
                                OCR, as well as the sites which refer visitors to aicommand.xyz.
                            </p>
                        </li>
                        <li>
                            <p>
                                Aggregated Information: In an ongoing effort to better understand and serve the users of the Services, we
                                may conduct research on our customer demographics, interests and behavior based on the information
                                collected. This research may be compiled and analyzed on an aggregate basis. We may also disclose aggregated
                                user statistics in order to describe our services to current and prospective business partners, and to other
                                third parties for other lawful purposes.
                            </p>
                        </li>
                        <li>
                            <p>
                                Cookies: We employ cookies and similar technologies such as browser storage to keep track of your local
                                computer’s settings such as which account you have logged into. Cookies are pieces of data that sites and
                                services can set on your browser or device that can be read on future visits. We may expand our use of
                                cookies to save additional data as new features are added to the Service. In addition, we use technologies
                                such as web beacons and single-pixel gifs to record log data such as open rates for emails sent by the
                                system.
                            </p>
                        </li>
                        <li>
                            <p>
                                We may use third party website analytics tools such as Google Analytics or Monitoring tools such as
                                Sentry.io in our software that employ cookies to collect certain information concerning your use of our
                                Services. However, you can disable cookies by changing your browser settings. Further information about the
                                procedure to follow in order to disable cookies can be found on your Internet browser provider&#x27;s
                                website via your help screen.
                            </p>
                        </li>
                        <li>
                            Advertisements: You may see our Service advertised in other applications or websites. After clicking on one of
                            these advertisements and installing our Service, you will become a user of the Service. Advertising platforms
                            may collect information for optimizing advertising campaigns outside of the Service.
                        </li>
                    </ul>
                    <p>
                        If you do not wish to receive personalized advertising that is delivered by third parties outside of the
                        aicommand.xyz Service, you may be able to exercise that choice through opt-out programs that are administered by
                        third parties, including the Network Advertising Initiative (NAI), the Digital Advertising Alliance (DAA). Our
                        Services currently do not respond to &quot;Do Not Track&quot; (DNT) signals and operate as described in this Privacy
                        Policy whether or not a DNT signal is received, as there is no consistent industry standard for compliance.
                    </p>
                    <h1>
                        <strong>WHERE INFORMATION IS PROCESSED</strong>
                    </h1>
                    <p>
                        The Company is based in the United States. No matter where you are located, you consent to the processing and
                        transferring of your information in and to the U.S. and other countries. The laws of the U.S. andother countries
                        governing data collection and use may not be as comprehensive or protective as the laws of the country where you
                        live.
                    </p>
                    <h1>
                        <strong>OUR USE OF YOUR INFORMATION</strong>
                    </h1>
                    <p>
                        We use the information you provide in a manner that is consistent with this Privacy Policy. If you provide
                        information for a certain reason, we may use the information in connection with thereason for which it was provided.
                        For instance, if you contact us by email, we will use the information you provide to answer your question or resolve
                        your problem. Also, if you provide information in order to obtain access to the Services, wewill use your
                        information to provide you with access to such services and to monitor your use of such services. The Company and
                        its subsidiaries and affiliates (the &quot;Related Companies&quot;) may also use your information collected through
                        the Services to help us improve the content and functionality of the Services, to better understand our users and to
                        improve the Services. The Company and its affiliates may use this information to contact you in the future to tell
                        you about services webelieve will be of interest to you. If we do so, each marketing communication we send you will
                        contain instructions permitting you to &quot;opt-out&quot; of receiving future marketing communications. In
                        addition, if at any time you wish not to receive any future marketing communications or you wish to have your name
                        deleted from our mailing lists, please contact us as indicated below.
                    </p>
                    <h1>
                        <strong>OUR LEGAL BASES FOR HANDLING OF YOUR PERSONAL DATA</strong>
                    </h1>
                    <p>
                        The laws in some jurisdictions require companies to tell you about the legal ground they rely on to use or disclose
                        your personal data. To the extent those laws apply, our legal grounds are as follows:
                    </p>
                    <ul>
                        <li>
                            <p>
                                To honor our contractual commitments to you: Much of our processing of personal data is to meet our
                                contractual obligations to our users, or to take steps at users’ request in anticipation of entering intoa
                                contract with them. For example, we handle personal data on this basis to create your account and provide
                                our Services.
                            </p>
                        </li>
                        <li>
                            <p>
                                Legitimate interests: In many cases, we handle personal data on the ground that it furthers our legitimate
                                interestsin ways that are not overridden by the interests or fundamental rights and freedoms of the affected
                                individuals: This includes:
                            </p>
                            <ul>
                                <li>
                                    <p>Providing a safe and enjoyable user experience;</p>
                                </li>
                                <li>
                                    <p>Customer service;</p>
                                </li>
                                <li>
                                    <p>Marketing, e.g. sending emails or other communications to let you know about new features;</p>
                                </li>
                                <li>
                                    <p>Protecting our users, personnel, and property;</p>
                                </li>
                                <li>
                                    <p>
                                        Analyzing and improving our business, e.g. collecting information about how you use our Services to
                                        optimize the design and placement of certain features;
                                    </p>
                                </li>
                                <li>
                                    <p>Processing job applications;</p>
                                </li>
                                <li>Managing legal issues.</li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                Legal compliance: We need to use and disclose personal data in certain ways to comply with our legal
                                obligations.
                            </p>
                        </li>
                        <li>
                            <p>
                                To protect the vital interests of the individual or others: For example, we may collect or share personal
                                data to help resolve an urgent medical situation.
                            </p>
                        </li>
                        <li>
                            Consent: Where required by law, and in some other cases, we handle personal data on the basis of your implied or
                            express consent.
                        </li>
                    </ul>
                    <h1>
                        <strong>OUR DISCLOSURE OF YOUR INFORMATION</strong>
                    </h1>
                    <p>
                        The Company is not in the business of selling your information. We consider this information to be a vital part of
                        our relationship with you. There are, however, certaincircumstances in which we may share your information with
                        certain third parties, as set forth below:
                    </p>
                    <ul>
                        <li>
                            <p>
                                Business Transfers: As we develop our business, we might sell or buy businesses or assets. In the event of a
                                corporate sale, merger, reorganization, bankruptcy, dissolution or similar event, your information may be
                                part of the transferred assets.
                            </p>
                        </li>
                        <li>
                            <p>Consent: We may transfer your information with your consent.</p>
                        </li>
                        <li>
                            <p>
                                Agents, Consultants and Related Third Parties: Like many businesses, we sometimes hire other companies or
                                individuals to perform certain business-related functions. Examples of such functions include mailing
                                information, maintaining databases and processing payments.
                            </p>
                        </li>
                        <li>
                            <p>
                                Legal Requirements: We may disclose your information if required to do so by law or in the good faith belief
                                that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or
                                property of the Company or Related Companies, (iii) protect the personal safety of users of the Services or
                                the public, or (iv) protect against legal liability.
                            </p>
                        </li>
                        <li>
                            Aggregated or Non-identifiable Data: We may also share aggregated or non-personally identifiable information
                            with our partners or others forbusiness purposes.
                        </li>
                    </ul>
                    <h1>
                        <strong>UNSOLICITED INFORMATION</strong>
                    </h1>
                    <p>
                        You may provide us with ideas for new products or modifications toexisting products, and other unsolicited
                        submissions (collectively, &quot;Unsolicited Information&quot;). All Unsolicited Information shall be deemed to be
                        non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to
                        others without limitation or attribution.
                    </p>
                    <h1>
                        <strong>CHILDREN</strong>
                    </h1>
                    <p>
                        Our Services are for users age 13 and overand we do not knowingly collect personal information from children under
                        the age of 13. If you are a parent or guardian of a child under the age of 13 and believe he or she has disclosed
                        personal information to us please contact us at fxnoob71@gmail.com. For residents of the EEA, where processing of
                        personal information is based on consent, Image to Text (OCR) will not knowingly engage in that processing for users
                        under the age of consent established by applicable data protection law. If we learn that we are engaged in that
                        processing with such users, we will halt such processing and will take reasonable measuresto promptly remove
                        applicable information from our records.
                    </p>
                    <h1>
                        <strong>LINKS TO OTHER WEBSITES</strong>
                    </h1>
                    <p>
                        This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or
                        controlled by us (the &quot;Third Party Sites&quot;). The policies and procedures we described here do not apply to
                        the Third Party Sites. The links from the Services do not imply that we endorse or have reviewed the Third Party
                        Sites. We suggest contacting those sites directly for information on their privacy policies.
                    </p>
                    <h1>
                        <strong>DATA RETENTION</strong>
                    </h1>
                    <p>
                        We generally retain personal data for so long as it may berelevant to the purposes identified herein. To dispose of
                        personal data, we may anonymize it, delete it or take other appropriate steps. Data may persist in copies made for
                        backup and business continuity purposes for additional time.
                    </p>
                    <h1>
                        <strong>SECURITY</strong>
                    </h1>
                    <p>
                        We take reasonable steps to protect the information provided via the Services from loss, misuse, and unauthorized
                        access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or
                        error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special
                        care in deciding what information you send to us via email. Please keep this in mind when disclosing any information
                        via the Internet.
                    </p>
                    <h1>
                        <strong>YOUR DATA RIGHTS AND CHOICES</strong>
                    </h1>
                    <p>
                        We believe that users should be treated equally no matter where they are, andso we are making the following options
                        to control your data available to all users, regardless of their location. You canunsubscribe from certain emails by
                        clicking the &quot;unsubscribe&quot; link they contain. You can opt out from certain cookie-related processing by
                        following the instructions above in “Other Information.”Individuals in the European Economic Area, Canada, Costa
                        Rica and some other jurisdictions outside the United States have certain legal rights to obtain confirmation of
                        whether we hold personal data about them, to access personal data we hold about them (including, in some cases, in
                        portableform), and to obtain its correction, update, amendment or deletion in appropriate circumstances. They may
                        also object to our uses or disclosures of personal data, to request a restriction on its processing, or withdraw any
                        consent, though suchactions typically will not have retroactive effect. They also will not affect our ability to
                        continue processing data in lawful ways.
                    </p>
                    <ul>
                        <li>How can I access the personal data you have about me?</li>
                    </ul>
                    <p>
                        If you would like to submit a data access request, you can do so by emailing us at fxnoob71@gmail.com. We will then
                        start the process and provide you a link to accessthe personal data that Chat Anywhere has on you within 30 days.
                    </p>
                    <ul>
                        <li>How do I correct, update, amend, or delete the personal datayou have about me?</li>
                    </ul>
                    <p>
                        In addition to the functionality available through the &quot;Settings&quot; of the Services, in which you
                        cancorrect, update, amend, or delete certain personal data, you can also request other modifications from us
                        directly. Please write us at fxnoob71@gmail.com with the words “Personal Data Request” in the subject or body of
                        your message, along with an explanation of what data subject right you are seeking to exercise. For your protection,
                        we may take steps to verify identity before responding to your request.
                    </p>
                    <ul>
                        <li>How do I object or restrict the manner in which Image to Text (OCR) processes my personal data?</li>
                    </ul>
                    <p>
                        You have a right to ask us to stop using or limit our use of your personal data in certain circumstances—forexample,
                        if we have no lawful basis to keep using your data, or if you think your personal data is inaccurate. Individuals in
                        the European Economic Area have the right to opt out of all of our processing of their personal data for direct
                        marketing purposes. To exercise this right, email us at fxnoob71@gmail.com. You may also click the
                        &quot;unsubscribe&quot; link in anyof our marketing emails. The rights and options described above are subject to
                        limitations and exceptions under applicable law. In addition to those rights, you have the right to lodge a
                        complaint with the relevant supervisory authority. However, we encourage you to contact us first, and we will do our
                        very best to resolve your concern.
                    </p>
                    <h1>
                        <strong>EU-U.S. PRIVACY SHIELD AND SWISS-U.S. PRIVACY SHIELD</strong>
                    </h1>
                    <p>
                        aicommand.xyz complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as
                        set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information
                        transferred from the European Union and Switzerland to the United States, respectively. If there is any conflict
                        between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall
                        govern. To learn more about the Privacy Shield program, and to view our certification, please visit
                        <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>. aicommand.xyz is potentially liable for
                        onward transfers to third parties of personal dataof EU or Swiss individuals received pursuant to Privacy Shield.
                        aicommand.xyz is subject to oversight by the U.S. FTC. JAMS is the US-based alternative dispute resolution provider
                        responsible for reviewing and resolving complaints about Chat Anywhere’s Privacy Shield compliance. We ask that you
                        first submit any complaints to us at fxnoob71@gmail.com. If you aren&#x27;t satisfied with our response, you can
                        then contact JAMS at
                        <a href="https://www.jamsadr.com/eu-us-privacy-shield">https://www.jamsadr.com/eu-us-privacy-shield</a>. In the
                        event your concern still isn&#x27;t addressed by JAMS, you may be entitled to a binding arbitrationunder Privacy
                        Shield and its principles.
                    </p>
                    <h1>
                        <strong>CHANGES TO THIS PRIVACY POLICY</strong>
                    </h1>
                    <p>
                        We reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice.
                        Please review this policy periodically, and especially before you provide any information. This Privacy Policy was
                        last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this
                        Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.
                    </p>
                    <h1>
                        <strong>CONTACTING US</strong>
                    </h1>
                    <p>
                        Please also feel free to contact us if you have any questions about this Privacy Policy or the information practices
                        of the Services. You may contact us as follows: fxnoob71@gmail.com.
                    </p>
                </div>
            </div>
        </div>
    );
}
